<!--
  PACKAGE_NAME : src/pages/euc/phone/request/worker
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 2024-06-03
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="null"
        :customSearchList="searchType.list"
        :searchObj="searchType.obj"
        @change-search-type="$_changeSearchType"
        @onSearchClick="searchData($event)"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes.viewFl"
            placeholder="사용여부"
            :items="getViewFlList"
            display-expr="label"
            value-expr="value"
            styling-mode="outlined"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('viewFl', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="workerGridRef" @init-new-row="onInitNewRow" />

    <DxPopup
      v-model="modal.isOpened"
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :width="modal.initData ? modal.initData.width : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            ref="modalRef"
            v-if="modal.sendData"
            :is="modal.currentComponent"
            :modalData="modal.sendData"
            :isModal="modal.isModal"
          />
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: $_msgContents('COMPONENTS.SELECT', { defaultValue: '선택' }),
          width: '120',
          height: '40',
          onClick: () => onSelectedUser(),
        }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: $_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => isOpenModal(false),
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
  import { isEmpty, isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import Search from '@/components/common/search.vue';
  import ModalUserSelect from '@/components/euc/modal-user-select.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxPopup,
      DxToolbarItem,
      Search,
      ModalUserSelect,
    },
    data() {
      return {
        typeList: [],
        detailList: [],
        searchType: {
          list: [
            {
              codeNm: '사번',
              codeValue: 'userNo',
            },
            {
              codeNm: '이름',
              codeValue: 'userNm',
            },
            {
              codeNm: '설명',
              codeValue: 'description',
            },
            {
              codeNm: '유형',
              codeValue: 'typeNm',
            },
            {
              codeNm: '상세유형',
              codeValue: 'detailNm',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            viewFl: null,
          },
          paramsData: null,
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'workerGridRef',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 225px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_PHONE_REQUEST_WORKER_UPDATE',
            loading: true,
          },
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'selectUser',
              dataField: 'selectUser',
              width: 80,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              headerCellTemplate: container => {
                const icon = document.createElement('i');
                icon.className = 'dx-icon dx-icon-user';
                container.append(icon);
              },
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    text: '선택',
                    elementAttr: { class: 'btn_XS default filled add1 m-0' },
                    width: 60,
                    height: 30,
                    value: options.value,
                    onClick: () => {
                      options.onSelectedUser = user => {
                        const rowIndex = options.component.getRowIndexByKey(options.row.key);
                        options.component.cellValue(rowIndex, 'userNo', user.userNo);
                        options.component.cellValue(rowIndex, 'userNm', user.userNm);
                      };
                      this.showUserModal(options);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
            {
              caption: '사번',
              dataField: 'userNo',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: this.$_msgContents('COMMON.REQUIRED_VALUE', { defaultValue: '필수값입니다.' }),
              },
            },
            {
              caption: '이름',
              dataField: 'userNm',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: this.$_msgContents('COMMON.REQUIRED_VALUE', { defaultValue: '필수값입니다.' }),
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '유형',
              dataField: 'typeId',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: [],
                displayExpr: 'typeNm',
                valueExpr: 'id',
              },
              setCellValue: (newData, value) => {
                newData.typeId = value;
                newData.detailId = null;
              },
            },
            {
              caption: '상세유형',
              dataField: 'detailId',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: options => {
                  const returnData = {
                    store: this.detailList,
                    sort: '+detailOrd',
                  };

                  if (options.data?.typeId) {
                    returnData.filter = ['typeId', '=', options.data.typeId];
                  }
                  return returnData;
                },
                displayExpr: 'detailNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: this.$_msgContents('COMMON.REQUIRED_VALUE', { defaultValue: '필수값입니다.' }),
              },
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '수정자',
              dataField: 'editId',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일',
              dataField: 'editDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: row => this.formatDt(row.editDt),
            },
          ],
        },
      };
    },
    computed: {
      getViewFlList() {
        return [
          {
            label: '전체',
            value: null,
          },
          ...this.$_enums.common.stringUsedFlag.values,
        ];
      },
    },
    methods: {
      searchData(paramsData) {
        //검색 버튼 클릭시 이벤트
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          //검색 키워드의 값이 없으면
          //검색키는 설정하고, 검색어를 입력하지 않으면, 전체 목록 출력
          let codeValues = this.searchType.list.map(d => d.codeValue);
          if (!isEmpty(this.searchType.paramsData)) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key]; //검색 조건 키 삭제
              }
            });
          }
        }
        this.selectDataList();
      },
      showUserModal(options) {
        this.onOpenModal(
          'ModalUserSelect',
          {
            title: this.$_msgContents('UC.WORD.WORKER_SELECT', { defaultValue: '작업자 선택' }),
            width: '50vw',
            height: 'calc(100vh - 100px)',
            buttons: {
              save: { text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }) },
              cancel: { text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }) },
            },
          },
          options,
        );
      },
      onSelectedUser() {
        const user = this.$refs.modalRef.getSelectUser();
        if (!user?.[0] || !user[0].isUser) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.REQUIRED_SELECT_USER', { defaultValue: '사용자를 선택해주세요.' }), {
            icon: 'info',
          });
          return;
        }
        this.modal.sendData.onSelectedUser(user[0]);
        this.isOpenModal(false);
      },
      onOpenModal(componentNm, componentInitData, sendData) {
        sendData.privilege = 'worker';
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.sendData = sendData;
        this.isOpenModal(true);
      },
      isOpenModal(bool) {
        if (!bool) {
          this.$refs.modalRef.clearSelection();
          this.modal.sendData = null;
        }
        this.modal.isOpened = bool;
      },
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
      },
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionname: 'EUC_PHONE_REQUEST_WORKER_SELECT',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      formatDt(date, toFormat = 'YYYY.MM.DD HH:mm:ss') {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', toFormat);
        }
      },
      async selectTypeDataList(sort = '+typeOrd') {
        const params = {
          sort,
          viewFl: 'Y',
          pagesize: 10000,
        };
        const payload = {
          actionname: 'EUC_PHONE_REQUEST_TYPE_SELECT',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.typeList = res.data.data;
          this.dataGrid.columns.find(column => column.dataField === 'typeId').lookup.dataSource = this.typeList;
        }
      },
      async selectDetailDataList(sort = '+detailOrd') {
        const params = {
          sort,
          viewFl: 'Y',
          pagesize: 10000,
        };
        const payload = {
          actionname: 'EUC_PHONE_REQUEST_TYPE_DETAIL_SELECT',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.detailList = res.data.data;
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {
      this.selectTypeDataList();
      this.selectDetailDataList(); // 초기 데이터 생성 시 필요한 데이터 조회
    },
  };
</script>
<style scoped></style>